import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import HeaderText from '../../components/personal/HeaderText';
import SetTitle from '../../components/personal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';

import { debounce, scrollElementToTop } from '../../utilities/generic/Common.js'

import { renderRequired, requiredString } from '../../utilities/generic/Forms.js'
import { handleCustomAttributes } from '../../utilities/personal/Forms.js'

class CustomAttributes extends Component {

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.debouncedScrollElementToTop = debounce(scrollElementToTop, 1000);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  state = {
    custom_attributes: this.customAttributesInitialiser(),
    showConfirmationMessage: this.containsExistingData(),

    updated: false,
    errors: "",
    error: "",

    direction: "arriving"
  }

  customAttributesInitialiser() {
    if (this.props.location.state) {
      let custom_attributes = {}

      const post_arrival_custom_attributes = this.props.location.state.profile.custom_attributes.filter(
         attribute => !(attribute.required === true && attribute.answer_type === "text")
      )

      // this creates empty values for new custom attributes
      post_arrival_custom_attributes.forEach((custom_attribute) => {
        custom_attributes[custom_attribute.id] = ""
      })

      // this fills in already created custom attributes
      let person_custom_attributes = this.props.location.state.person.custom_attributes
      person_custom_attributes.forEach((person_custom_attribute) => {
        custom_attributes[person_custom_attribute.id] = person_custom_attribute.value
      })

      return (custom_attributes)
    }
    else {
      return {}
    }
  }

  containsExistingData() {
    // Protect against people visiting /custom-attributes page directly
    if (this.props.location.state === undefined) {
      return false
    }

    // return true if any custom_attribute_id from existing data matches any custom_attribute.id from the profile
    // so long as it's not only acknowledgements
    if (this.props.location.state.person.custom_attributes.length > 0) {
      const post_arrival_custom_attributes = this.props.location.state.profile.custom_attributes.filter(
         attribute => !(attribute.required === true && attribute.answer_type === "text")
      )

      return post_arrival_custom_attributes.some(person_custom_attribute =>
        this.props.location.state.profile.custom_attributes.some(profile_custom_attribute =>
          person_custom_attribute.id === profile_custom_attribute.id && profile_custom_attribute.answer_type !== "acknowledgement"
        )
      )
    }
    else return false
  }

  handleChange(event) {
    let custom_attribute_id = event.target.name;
    let value = event.target.value;

    let custom_attributes = this.state.custom_attributes;

    custom_attributes[custom_attribute_id] = value

    this.setState({custom_attributes: custom_attributes});

    if (event.target.type === "text") {
      this.debouncedScrollElementToTop(event.target)
    }
    else {
      scrollElementToTop(event.target)
    }
  }

  handleCheckbox(event) {
    let custom_attribute_id = parseInt(event.target.name);
    let value = event.target.value;
    let checked = event.target.checked;

    let custom_attributes = this.state.custom_attributes;

    custom_attributes[custom_attribute_id] = checked ? value : ""

    this.setState({custom_attributes: custom_attributes});

    scrollElementToTop(event.target)
  }

  handleSubmit(event) {
    if (this.validator.allValid()) {
      handleCustomAttributes(this, this.state.custom_attributes, this.props.location.state.arriving_app_id)
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }

    event.preventDefault();
  }

  handleClose() {
    this.setState({ showConfirmationMessage: false })
  }

  alreadyAcknowledged(profile_custom_attribute_id) {
    const person_custom_attributes = this.props.location.state.person.custom_attributes
    const previousAcknoledgement = person_custom_attributes.find((custom_attribute) => custom_attribute.id === profile_custom_attribute_id && custom_attribute.value === "Acknowledged")
    return previousAcknoledgement !== undefined
  }

  renderConfirmation() {
    if (this.state.showConfirmationMessage) {
      return (
      <div className="autocomplete-container">
        <div className="autocomplete-content">
          Please confirm this information is correct:
          <button className="autocomplete-button" value="ok" onClick={this.handleClose}>OK</button>
        </div>
      </div>
      )
    }
  }

  renderProfileCustomAttributes(profile_custom_attribute) {
    let person_custom_attributes = this.state.custom_attributes

    if (profile_custom_attribute.answer_type === "boolean") {
      return (
        <div className="feedback-page" key={profile_custom_attribute.id}>
          <div className="question radio">
            <div>{renderRequired(profile_custom_attribute.required)}{profile_custom_attribute.name}</div>
            <input className="column" type="checkbox" name={profile_custom_attribute.id} value="Yes" id={`yes_${profile_custom_attribute.id}`} checked={person_custom_attributes[profile_custom_attribute.id] === "Yes"} onChange={this.handleChange} />
            <label className="longer" htmlFor={`yes_${profile_custom_attribute.id}`}>Yes</label>
            <input className="column" type="checkbox" name={profile_custom_attribute.id} value="No" id={`no_${profile_custom_attribute.id}`} checked={person_custom_attributes[profile_custom_attribute.id] === "No"} onChange={this.handleChange} />
            <label className="longer" htmlFor={`no_${profile_custom_attribute.id}`}>No</label>
            {this.validator.message('above', person_custom_attributes[profile_custom_attribute.id], requiredString(profile_custom_attribute.required, "string"), { className: 'error' })}
          </div>
        </div>
      )
    }

    if (profile_custom_attribute.answer_type === "acknowledgement") {
      // If an acknowledgement has previously been acknowledged, we do not show them the acknowledment again
      if (this.alreadyAcknowledged(profile_custom_attribute.id)){
        return
      }
      else {
        return (
          <div key={profile_custom_attribute.id}>
            <div className="message" htmlFor="checkbox">{renderRequired(profile_custom_attribute.required)}{profile_custom_attribute.name}</div>
            <input className="message" type="checkbox" name={profile_custom_attribute.id} value="Acknowledged" id={profile_custom_attribute.id} checked={person_custom_attributes[profile_custom_attribute.id] === "Acknowledged"} onChange={this.handleCheckbox} />
            {this.validator.message('above', person_custom_attributes[profile_custom_attribute.id], requiredString(profile_custom_attribute.required, "string"), { className: 'error' })}
          </div>
        )
      }
    }

    else {
      return (
        <label key={profile_custom_attribute.id}>
          <div className="text">{renderRequired(profile_custom_attribute.required)}{profile_custom_attribute.name}:</div>
          <input aria-required="true" type="text" name={profile_custom_attribute.id} value={person_custom_attributes[profile_custom_attribute.id]} autoComplete="off" onChange={this.handleChange} />
          <div className="blank"></div>
          {this.validator.message('above', person_custom_attributes[profile_custom_attribute.id], requiredString(profile_custom_attribute.required, "string"), { className: 'error' })}
        </label>
      )
    }
  }

  render() {
    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    const { updated, errors, error } = this.state;
    let profile = this.props.location.state.profile;

    if (updated) {
      let token = this.props.location.state.token
      let arriving_app_id = this.props.location.state.arriving_app_id
      let person = this.props.location.state.person
      let direction = this.props.location.state.direction
      let planned_event_ids = this.props.location.state.planned_event_ids

      return <DestinationSwitch current_page="custom-attributes" profile={profile} person={person} arriving_app_id={arriving_app_id} direction={direction} token={token} personal={true} planned_event_ids={planned_event_ids} />
    }


    if (errors) {
      return (
        <div className="errors">
          Sorry, looks like you have some errors:
          <ul>
            {errors.errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>
      )
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    const post_arrival_custom_attributes = profile.custom_attributes.filter(
       attribute => !(attribute.required === true && attribute.answer_type === "text")
    )

    return (
      <div className="main-page arriving-page">

        <SetTitle title={"Custom Attributes"} profile={profile} />
        <HeaderText message={profile.welcome_message} />

        <div className="content">
          <div className="widget form">
            <form autoComplete="off" onSubmit={this.handleSubmit}>
              <div className="message">
                Please fill in the following details if you are able:
              </div>

              {post_arrival_custom_attributes.map((profile_custom_attribute) => {
                return this.renderProfileCustomAttributes(profile_custom_attribute)
              })}
              <br />
              <input type="submit" value="Submit" />
            </form>

            {this.renderConfirmation()}
          </div>
        </div>

        <div></div>
      </div>
    );
  }
}

export default CustomAttributes;
