import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

// Switch logic without a default case and with fallthrough is by design here, so we disable those warnings
/* eslint-disable no-fallthrough, default-case */

class DestinationSwitch extends Component {
  // Redirects
  render() {
    switch (this.props.current_page) {
      // arriving only
      case "arriving":
        if (this.containsPostArrivalCustomAttributes()) {
          return this.customAttributesPage()
        }
      case "custom-attributes":
        if (this.covidScanRequired()) {
          return this.covidQRPage()
        }
      case "covid-qr":
        if (this.unplannedEvent() && this.canVisitPeople() && this.hasPeopleToVisit()) {
          return this.visitingWhoPage()
        }
      // arriving or leaving
      case "leaving":
      case "visiting-who":
        let feedbackTypes = this.calculateFeedbackTypes()
        if (feedbackTypes.length > 0) {
          return this.feedbackPage(feedbackTypes)
        }
      case "feedback":
        if (this.containsInformation()) {
          return this.informationPage()
        }

      case "information":
        return this.thankYouPage()
    }
  }

  renderURL(path) {
    if (this.props.personal) {
      return "/personal" + path
    }
    else {
      return path
    }
  }

  // Conditional logic
  containsPostArrivalCustomAttributes() {
    // always skip if Profile requires no Custom Attributes
    if (this.props.profile.custom_attributes && this.props.profile.custom_attributes.length === 0) {
      return false
    }

    // take a note of pre-existing Person Custom Attributes with a value of "Acknowledged", if any
    const acknowledgedCustomAttributeIDs = this.props.person.custom_attributes.filter(
      (person_custom_attribute) => person_custom_attribute.value === "Acknowledged"
    ).map(
      (person_custom_attribute) => person_custom_attribute.id
    );

    // `Required` `Text` Custom Attributes are now shown on the Arrivals page.
    // Filtering them out of this collection.
    const postArrivalCustomAttributes = this.props.profile.custom_attributes.filter(
      attribute => !(attribute.required === true && attribute.answer_type === "text")
    )

    // filter out their corresponding Profile Custom Attributes if they are also currently set as the "acknowledgement" answer_type
    const shownCustomAttributes = postArrivalCustomAttributes.filter(
      (profile_custom_attribute) => profile_custom_attribute.answer_type !== "acknowledgement" || !acknowledgedCustomAttributeIDs.includes(profile_custom_attribute.id)
    );

    return shownCustomAttributes.length > 0
  }

  covidScanRequired() {
    return (this.props.profile.covid_pass_check_required && new Date(this.props.person.covid_pass_expiry_date) < new Date().setHours(0,0,0,0) && this.props.person.covid_pass_exemption !== true)
  }

  unplannedEvent() {
    return (this.props.planned_event_ids.length === 0)
  }

  canVisitPeople() {
    // TODO: Remove skip_visiting_people clause (deprecated)
    return (this.props.profile.ask_visiting_people !== "never" && this.props.profile.skip_visiting_people !== true)
  }

  hasPeopleToVisit() {
    return (this.hasRelationships() || this.hasVisitAllEnabled() || this.props.profile.visitable_types_of_people.length > 0)
  }

  hasRelationships() {
    return (this.props.person.relationships !== undefined && this.props.person.relationships.length > 0)
  }

  hasVisitAllEnabled() {
    return (this.props.person.visit_all !== null && this.props.person.visit_all !== undefined)
  }

  calculateFeedbackTypes() {
    let feedbackTypes = [];

    if (this.props.direction === "arriving") {
      if (this.props.profile.feedback_on_arrival === true) {
        this.props.profile.feedback_types.forEach((feedback) => {
          if (feedback.show_on === "Arrival" && feedback.feedback_questions.length > 0) {
            feedbackTypes.push(feedback)
          }
        })
      }
    }
    else {
      this.props.profile.feedback_types.forEach((feedback) => {
        if (feedback.show_on === "Departure" && feedback.feedback_questions.length > 0) {
            feedbackTypes.push(feedback)
        }
      })
    }

    if (this.props.person.feedback_answer_times !== null) {
      feedbackTypes = feedbackTypes.filter((feedbackType) => {
        if (feedbackType.frequency === "always" || this.props.person.feedback_answer_times[feedbackType.id] === undefined) {
          return true
        }

        if (feedbackType.frequency === "once") {
          return feedbackType.ask_from > this.props.person.feedback_answer_times[feedbackType.id]
        }

        let answerDate = new Date(this.props.person.feedback_answer_times[feedbackType.id])
        let currentDate = this.applyServerOffset(new Date())

        switch (feedbackType.frequency) {
          case "weekly":
            return (currentDate - answerDate) > 604800000 // 1000 * 60 * 60 * 24 * 7
          case "monthly":
            return (currentDate - answerDate) > 2419200000 // 1000 * 60 * 60 * 24 * 7 * 4
          default:
            return true
        }
      })
    }

    return feedbackTypes
  }

  applyServerOffset(date) {
    let server_offset = parseInt(localStorage.getItem("server_offset"))

    if (server_offset) {
      return new Date(date - (server_offset * 1000))
    }

    return date
  }

  containsInformation() {
    return ((this.props.direction === "arriving" && this.props.profile.information_on_check_in !== null && this.props.profile.information_on_check_in !== "") ||
    (this.props.direction === "leaving" && this.props.profile.information_on_check_out !== null && this.props.profile.information_on_check_out !== ""))
  }

  customAttributesPage() {
    return <Redirect to={{ pathname: this.renderURL("/custom-attributes"), state: { token: this.props.token, profile: this.props.profile, person: this.props.person, arriving_app_id: this.props.arriving_app_id, direction: this.props.direction, personal: this.props.personal, planned_event_ids: this.props.planned_event_ids} }}/>
  }

  covidQRPage() {
    return <Redirect to={{ pathname: this.renderURL("/covid-qr"), state: { token: this.props.token, profile: this.props.profile, person: this.props.person, arriving_app_id: this.props.arriving_app_id, direction: this.props.direction, personal: this.props.personal, planned_event_ids: this.props.planned_event_ids} }}/>
  }

  visitingWhoPage() {
    return <Redirect to={{ pathname: this.renderURL("/visiting-who"), state: { token: this.props.token, relationships: this.props.person.relationships, profile: this.props.profile, person: this.props.person, arriving_app_id: this.props.arriving_app_id, direction: this.props.direction, personal: this.props.personal} }}/>
  }

  feedbackPage(feedbackTypes) {
    const directional_app_id = (this.props.direction === "arriving") ? this.props.arriving_app_id : this.props.leaving_app_id

    return <Redirect to={{ pathname: this.renderURL("/feedback"), state: { token: this.props.token, profile: this.props.profile, directional_app_id: directional_app_id, direction: this.props.direction, personal: this.props.personal, feedbackTypes: feedbackTypes} }}/>
  }

  informationPage() {
    return <Redirect to={{ pathname: this.renderURL("/information"), state: { profile: this.props.profile, direction: this.props.direction, personal: this.props.personal } }}/>
  }

  thankYouPage() {
    return <Redirect to={{ pathname: this.renderURL("/thank-you"), state: { profile: this.props.profile, direction: this.props.direction, personal: this.props.personal } }}/>
  }
}

export default DestinationSwitch;
