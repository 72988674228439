import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import BigLogo from '../../components/setup/BigLogo';
import SetTitle from '../../components/communal/SetTitle';

import { handleChange } from '../../utilities/generic/Forms.js'

class DeviceSetup extends Component {

  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    deviceSelected: false,
    device_id: '',

    devices: [],

    loaded: false,
    unauthorized: false
  };

  deleteOrphanedEvents(new_device_id) {
    if (localStorage !== null) {
      const old_device_id = parseInt(localStorage.getItem("device_id"))

      if (old_device_id !== new_device_id) {
        localStorage.clear()
        localStorage.setItem("device_id", new_device_id)
      }
    }
  }

  fetchData(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "setup": {
        "email_address": this.props.location.state.emailAddress,
        "password": this.props.location.state.password,
        "provider": this.props.location.state.provider,
        "credential": this.props.location.state.credential,
        "location_id": this.props.location.state.locationId
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/setup/device`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 400) {
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ devices: data, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))

    event && event.preventDefault();
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "setup": {
        "email_address": this.props.location.state.emailAddress,
        "password": this.props.location.state.password,
        "provider": this.props.location.state.provider,
        "credential": this.props.location.state.credential,
        "device_id": this.state.device_id
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/setup/device/set`, requestOptions)
    .then(res => res.json())
      .then((data) => {
        if (data.cookie) {
          this.deleteOrphanedEvents(data.device_id)
          const cookies = new Cookies();
          cookies.set('device_token', data.cookie, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN, maxAge: 31557600 });

          this.setState({deviceSelected: true})
        }
        else if (data.errors) {
          this.setState({errors: JSON.stringify(data)})
        }
      })
      .catch(error => this.setState({error: error.message}))

    event.preventDefault();
  }

  render() {
    const { device_id, devices, loaded, deviceSelected, unauthorized } = this.state;

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    if (deviceSelected) {
      return <Redirect to={{ pathname: "/setup/camera", state: { locationId: this.props.location.state.location_id, emailAddress: this.props.location.state.emailAddress, password: this.props.location.state.password, provider: this.props.location.state.provider, credential: this.props.location.state.credential } }} />
    }

    if (unauthorized) {
      return <Redirect to="/"/>
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && devices.length === 0) {
      const locationName = this.props.location.state.locationName || "this Location"

      return (
        <div className="center-container">
          <SetTitle title={"Select Device | Device Setup"} />

          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            <div className="login-form">
              <div className="center">
                <h3>Select Device</h3>
                <p>
                  There are no Devices set up for {locationName}. Please create a new Device for {locationName} on app.dreception.com
                </p>

                <p>
                  Once a Device has been set up, press the button below to allow the newly created Device to be selected.
                </p>
              </div>

              <form onSubmit={this.fetchData}>
                <input type="submit" value="Reload Devices list" />
              </form>
            </div>

          </div>
        </div>
      )
    }

    if (loaded) {
      return (
        <div className="center-container">
          <SetTitle title={"Select Device | Device Setup"} />

          <div className="login-box">
            <p className="welcome-to">Welcome to...</p>

            <BigLogo />

            <div className="login-form">
              <div className="center">
                <h3>Select Device</h3>
                <p>
                  Choose which device you'd like to set up today.
                </p>
              </div>

              <form onSubmit={this.handleSubmit}>
                <label>
                  <select name="device_id" onChange={this.handleChange} value={device_id}>
                    <option value="select">Select a device</option>
                    {devices.map((device) => (
                      <option value={device.id} key={device.id}>{device.name}</option>
                    ))}
                  </select>
                </label>
                <br />
                <input type="submit" value="Continue" />
              </form>
            </div>

          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      return
    }

    this.fetchData()
  }
}

export default DeviceSetup;
