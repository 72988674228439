import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';

import { validateColorString } from '../../utilities/generic/Common'

class FetchContent extends Component {

  state = {
    error: null,
    unauthorized: null
  };

  render() {
    const { unauthorized } = this.state;

    if (unauthorized) {
      return <Redirect to="/setup/user"/> 
    }

    // This component is just here to pull down necessary data.
    // We don't need to return anything to the page
    return (
      <>
      </>
    );
  }

  build_params() {
    let params = "";
    const updated_at = localStorage.getItem("updated_at");

    if (updated_at) {
      params = "?pull[updated_at]=" + updated_at
    }

    return params
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    const params = this.build_params();

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/pull${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
          return "unauthorized"
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (data !== "unauthorized") {
          // allow the api to tell any device to clear its event localStorage in an emergency
          if (data.dump_localstorage) {
            localStorage.setItem("arrivals", "[]")
            localStorage.setItem("custom_attributes", "[]")
            localStorage.setItem("covid_results", "[]")
            localStorage.setItem("visiting", "[]")
            localStorage.setItem("departures", "[]")
            localStorage.setItem("answers", "[]")
            localStorage.setItem("dumped_localstorage", "true")
          }

          // calculate server offset in seconds
          let serverTime = parseInt(data.server_time)
          let server_offset = Math.floor((new Date().getTime() - serverTime) / 1000) // `getTime()` gives us a UTC epoch integer
          localStorage.setItem("server_offset", JSON.stringify(server_offset))

          if (data.id) {
            localStorage.setItem("id", JSON.stringify(data.id))
            localStorage.setItem("device", JSON.stringify(data.device))
            localStorage.setItem("device_id", JSON.stringify(data.device_id))
            localStorage.setItem("location", JSON.stringify(data.location))
            localStorage.setItem("background_image", JSON.stringify(data.background_image))
            localStorage.setItem("logo", JSON.stringify(data.logo))
            localStorage.setItem("fire_log_access_pin", data.fire_log_access_pin)
            localStorage.setItem("fire_log_order", JSON.stringify(data.fire_log_order))
            localStorage.setItem("planned_events", JSON.stringify(data.planned_events))
            localStorage.setItem("profiles", JSON.stringify(data.profiles))
            localStorage.setItem("people", JSON.stringify(data.people))
            localStorage.setItem("checked_in_people", JSON.stringify(data.checked_in_people))
            localStorage.setItem("qr_code_size", data.qr_code_size)
            localStorage.setItem("profile_overflow", data.profile_overflow)
            localStorage.setItem("updated_at", JSON.stringify(data.updated_at))
            localStorage.setItem("flow_order", data.flow_order)
            localStorage.setItem("loaded", "true")

            if (validateColorString(data.border_color)){
              document.querySelector(':root').style.setProperty('--page-border-color', data.border_color)
            }
            else {
              document.querySelector(':root').style.setProperty('--page-border-color', null)
            }

            Honeybadger.setContext({
              deviceName: localStorage.device,
              physicalDeviceId: localStorage.id
            })
          }

          if (localStorage.getItem("force_refresh_time") === null || localStorage.getItem("force_refresh_time") < data.force_refresh_time) {
            localStorage.setItem("force_refresh_time", data.force_refresh_time);

            // trigger a fresh grab of api data on the next pull request, as it may be required.
            localStorage.removeItem("updated_at")

            // We call window.location.href = window.location.href here because that triggers a complete refresh from the server, where window.location.reload() may use the cached version.
            // eslint-disable-next-line
            window.location.href = window.location.href
          }

          if (this.props.firstTime === true) {
            window.location.reload()
          }

          this.props.handleFetchSuccess()
        }
      })
      .then(() => {
        this.props.updateHomePage();
      })
      .catch(error => {
        this.props.handleFetchFailure()
        this.setState({ error })
      })
  }
}

export default FetchContent;
